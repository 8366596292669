import { useRouteLoaderData } from 'react-router'
import { type loader as rootLoader } from '#app/routes/_app+/_layout.tsx'

interface User {
	id: string
	username: string
	display: string | null
	member: string | null
	image: {
		id: string
	} | null
	ports: {
		ditch: number
		position: number
		entry: string | null
		section: string | null
	}[]
	roles: {
		name: string
		permissions: {
			action: string
			entity: string
			access: string
		}[]
	}[]
}

function isUser(
	user: any,
): user is Awaited<ReturnType<typeof rootLoader>>['data']['user'] {
	return user && typeof user === 'object' && typeof user.id === 'string'
}

export function useOptionalUser(): User | null | undefined {
	const data = useRouteLoaderData<typeof rootLoader>('routes/_app+/_layout')
	if (!data || !isUser(data.user)) return undefined
	return data.user
}

export function useOptionalAdminUser() {
	return useOptionalUser()?.roles.some((r) => r.name === 'admin')
}

export function useUser(): User {
	const maybeUser = useOptionalUser()
	if (!maybeUser) {
		throw new Error(
			'No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.',
		)
	}
	return maybeUser
}

type Action = 'create' | 'read' | 'update' | 'delete'
type Entity = 'user' | 'meeting' | 'document' | 'schedule'
type Access = 'own' | 'any' | 'own,any' | 'any,own'
export type PermissionString =
	| `${Action}:${Entity}`
	| `${Action}:${Entity}:${Access}`
export function parsePermissionString(permissionString: PermissionString) {
	const [action, entity, access] = permissionString.split(':') as [
		Action,
		Entity,
		Access | undefined,
	]
	return {
		action,
		entity,
		access: access ? (access.split(',') as Array<Access>) : undefined,
	}
}

export function userHasPermission(
	user: Pick<ReturnType<typeof useUser>, 'roles'> | null | undefined,
	permission: PermissionString,
) {
	if (!user) return false
	const { action, entity, access } = parsePermissionString(permission)
	return user.roles.some((role) =>
		role.permissions.some(
			(permission) =>
				permission.entity === entity &&
				permission.action === action &&
				(!access || access.includes(permission.access)),
		),
	)
}

export function userHasRole(
	user: Pick<ReturnType<typeof useUser>, 'roles'> | null | undefined,
	role: string,
) {
	if (!user) return false
	return user.roles.some((r) => r.name === role)
}
